import React from "react";
import Banner from "../../containers/Banner/Banner";
function AboutUs() {
  return (
    <section className="about-us">
      <Banner />
      <div className="container about-us-content">
        <div className="row">
          <div className="col-12">
            <div className="content-title">
              <h1>SGB RENT A CAR NE YAPAR ?</h1>
            </div>
            <div className="content-text">
              <p>
                SGB RENT A CAR, deneyimli yönetici ekibiyle, dinamik ve
                disiplinli personelini bir araya getirerek siz değerli
                müşterilerimize en kaliteli hizmeti sunmaktadır. Taşımacılık
                sektöründe yaşanan yenilikler ve teknolojik gelişmelere ayak
                uyduran SGB RENT A CAR, sizlere mükemmeliyet odaklı bir hizmet
                anlayışıyla kaliteyi buluşturmayı hedeflemektedir. Firmamız,
                "Mutlak Müşteri Memnuniyeti" ilkesini benimsemiş olup, son model
                araçlardan oluşan deneyimli yönetici ve sürücü kadrosuyla tüm
                beklentilerinize en iyi şekilde cevap vermekten gurur duyar.
                Memnuniyetiniz, bizim en temel önceliğimizdir. Geniş iç hacme
                sahip araçlar, büyük bagaj kapasitesi, konforlu sürüş deneyimi
                sunan modellerimiz ve şoförlü veya şoförsüz kiralama
                seçeneklerimizle, ihtiyaçlarınıza en uygun aracı seçmenize
                yardımcı oluyoruz. Yapmanız gereken tek şey, bizimle iletişime
                geçerek kiralamak istediğiniz araç özelliklerini paylaşmak. SGB
                RENT A CAR olarak, günlük araç kiralamadan VIP Vito
                seçeneklerine kadar geniş araç yelpazemizle, güvenli ve müşteri
                odaklı kiralama hizmeti sunmaktan büyük onur duyarız. Her geçen
                gün, insan hayatını kolaylaştırma amacıyla ortaya çıkan yeni
                fikirlerden biri de araç kiralama hizmetidir. Bu hizmet, farklı
                alanlarda hayatınızı büyük ölçüde kolaylaştırmaktadır. Özellikle
                özel yolculuklarınızda ya da kalabalık arkadaş gruplarınızla
                yapacağınız seyahatlerde, ihtiyacınıza en uygun özelliklere
                sahip araçları seçerek istediğiniz süre boyunca kiralama
                avantajlarından yararlanabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
