import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./containers/Navbar/Navbar";
import Footer from "./containers/Footer/Footer";
import Home from "./pages/Home/Home";
import OurServices from "./pages/OurServices/ourServices";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import ServiceDetail from "./pages/OurServices/ServiceDetail";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hizmetlerimiz" element={<OurServices />} />
        <Route path="/hakkimizda" element={<AboutUs />} />
        <Route path="/bize-ulasın" element={<ContactUs />} />
        <Route path="/services/:id" element={<ServiceDetail />} />
        <Route path="/gizlilik-politikası" element={<PrivacyPolicy />} />
        <Route path="/cerez-politikası" element={<CookiePolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
