import React from "react";
import bannerImg from "./img-1.jpg";

function Banner() {
  return (
    <section className="banner">
      <img src={bannerImg} alt="" className="w-100 h-100" />
      <div className="container">
        <div className="row banner-content">
          <h1>İSTANBUL ŞOFÖRLÜ ARAÇ KİRALAMA</h1>
          <p>
            SGB Rent A Car, İstanbul'da araç kiralama hizmeti sunarak
            müşterilerimize güvenli ve konforlu seyahat imkânı sağlıyoruz.{" "}
            <br />
            Profesyonel hizmetimizle her yolculuğunuzda yanınızdayız.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Banner;
