import React from "react";

const MapEmbed = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1503.9723367759414!2d29.011026!3d41.070202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab644f8bc39ef%3A0x670d8ec5d45a01ff!2sAslan%20Ozcakir%20Yegane%20Apt%20Ofis!5e0!3m2!1str!2str!4v1728438786373!5m2!1str!2str"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default MapEmbed;
