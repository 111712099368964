import React from "react";
import { Link } from "react-router-dom";
import OurServicesData from "./data.json";

function ourServices() {
  return (
    <section className="our-services">
      <div className="container">
        <h2 className="our-services-title">Hizmetlerimiz</h2>
        <div className="row gy-3 pb-3 our-services-cards">
          {OurServicesData.map((item, index) => {
            return (
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-img">
                    <img src={item.img} alt="resim" className="w-100" />
                  </div>
                  <div className="card-content">
                    <h4>{item.title} </h4>
                    <p>{item.text} </p>
                    <Link to={`/services/${item.id}`} className="detail-button">
                      Detaylar
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ourServices;
