import React from "react";
import footerData from "./data.json";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 content text-center">
            <h5>İstanbul SGB Rent A Car</h5>
            <p>
              İstanbul SGB Rent A Car, profesyonel ekibiyle İstanbul'da saygın
              müşterilerine Şoförlü VIP Vito ve Şoförlü lüks Mercedes kiralama
              hizmeti sunmaktadır. Konforlu ve güvenli bir yolculuk için bizi
              tercih edin.
            </p>
          </div>
          <div className="col-lg-6 text-center links">
            <h5>Menüler</h5>
            <ul className="m-0 p-0">
              {footerData.map((item, index) => {
                return (
                  <li>
                    <a href={item.link}>{item.text} </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
