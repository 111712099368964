import React from "react";
import MapEmbed from "../../components/mapEmbed";
function ContactUs() {
  return (
    <section className="contact-us">
      <div className="container">
        <MapEmbed />
        <div className="contact-us-content">
          <p>İletişim</p>
          <p>
            <a href="mailto:info@sgbistanbuliralama.com">
              info@sgbistanbuliralama.com
            </a>
          </p>
          <p>+(90) 534 934 40 96 </p>
          <div className="social-icon">
            <h4>Sosyal Medya</h4>
            <ul className="m-0 p-0">
              <li>
                <a href="https://www.instagram.com/otugrad/">
                  <i>
                    <i class="fa-brands fa-instagram"></i>
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
