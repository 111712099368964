import React from "react";
import { useParams } from "react-router-dom";
import OurServicesData from "./data.json";

function ServiceDetail() {
  const { id } = useParams();
  const service = OurServicesData.find((item) => item.id === parseInt(id));

  if (!service) {
    return <div>Hizmet bulunamadı!</div>;
  }

  return (
    <div className="service-detail">
      <div className="container">
        <div className="row justify-content-center">
          <h1>{service.title}</h1>
          <div className="text-center">
            <img src={service.img} alt={service.title} />
          </div>
          <div className="col-lg-6">
            <p>{service.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceDetail;
