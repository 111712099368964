import React from "react";

function CookiePolicy() {
  return (
    <section className="privacy-policy">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h1>SGB Rent A Car Çerez Politikası</h1>
              <p>
                SGB Rent A Car olarak, web sitemizde kullanıcı deneyimini
                iyileştirmek ve size daha iyi hizmet sunmak için çerezler
                kullanıyoruz. Bu politika, çerezlerin ne olduğunu, nasıl
                kullanıldığını ve çerez tercihlerinizi nasıl yönetebileceğinizi
                açıklamaktadır.
              </p>
            </div>
            <h2>1. Çerez Nedir?</h2>
            <p>
              Çerezler, web sitelerinin tarayıcınıza gönderdiği küçük veri
              dosyalarıdır. Bu dosyalar, sitede gezindiğiniz süre boyunca
              tercihlerinizi hatırlamak ve siteyi daha verimli hale getirmek
              amacıyla kullanılır.
            </p>
            <h2>2. Hangi Tür Çerezleri Kullanıyoruz?</h2>
            <p>
              Zorunlu Çerezler: Web sitemizin düzgün çalışması için gerekli olan
              çerezlerdir. Bu çerezler olmadan, site işlevlerinin tamamı
              kullanılamayabilir. Performans Çerezleri: Web sitemizin
              performansını analiz etmek ve kullanıcıların nasıl etkileşimde
              bulunduğunu anlamak için kullanılır. Bu çerezler anonim veri
              toplar. İşlevsellik Çerezleri: Sitemizi ziyaret ettiğinizde dil
              tercihlerinizi veya diğer kişiselleştirilmiş ayarlarınızı
              hatırlamak için kullanılır. Hedefleme/Reklam Çerezleri: Size daha
              uygun reklamlar gösterebilmek için kullanılabilir. Ayrıca, bu
              çerezler size gösterilen reklamların sayısını sınırlamaya ve
              reklam kampanyalarının etkinliğini ölçmeye yardımcı olur.
            </p>
            <h2>3. Çerezlerin Kullanım Amaçları</h2>
            <p>
              Çerezleri, web sitemizi daha kullanıcı dostu hale getirmek,
              hizmetlerimizi geliştirmek ve ziyaretçi davranışlarını analiz
              etmek için kullanıyoruz. Ayrıca, size daha alakalı içerikler ve
              reklamlar sunmak amacıyla da kullanılabilir.
            </p>
            <h2>4. Üçüncü Taraf Çerezleri</h2>
            <p>
              Sitemizde, üçüncü taraf hizmet sağlayıcıları tarafından
              yerleştirilen çerezler de kullanılabilir. Bu çerezler, üçüncü
              tarafların kendi hizmetleri (örneğin, Google Analytics) için veri
              toplamasına ve analiz yapmasına olanak tanır.
            </p>
            <h2>5. Çerez Tercihlerinizi Nasıl Yönetebilirsiniz?</h2>
            <p>
              Tarayıcı ayarlarınızı kullanarak çerezleri kabul etme, reddetme
              veya silme seçeneklerine sahipsiniz. Ancak, çerezlerin devre dışı
              bırakılması durumunda web sitemizin bazı özellikleri
              çalışmayabilir veya istenen şekilde çalışmayabilir.
            </p>
            <h2>6. Çerez Politikası Değişiklikleri</h2>
            <p>
              Bu çerez politikası, yasal düzenlemelere ve hizmetlerimizde
              yapılacak değişikliklere bağlı olarak zaman zaman güncellenebilir.
              Herhangi bir değişiklik yapıldığında, güncel politikamız web
              sitemizde yayımlanacaktır.
            </p>
            <h2>7. İletişim</h2>
            <p>
              Çerez politikamız veya çerezlerin kullanımı ile ilgili sorularınız
              varsa, bizimle [e-posta adresi] üzerinden iletişime
              geçebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CookiePolicy;
