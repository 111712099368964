import React from "react";

function PrivacyPolicy() {
  return (
    <section className="privacy-policy">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h1>Gizlilik Politikamız</h1>
            </div>
            <h2>1. Kişisel Verilerin Toplanması ve Kullanımı</h2>
            <p>
              SGB Rent a Car olarak, kullanıcılarımızın gizliliğine büyük önem
              veriyoruz. Web sitemizi ziyaret ettiğinizde, rezervasyon işlemleri
              ve müşteri hizmetleri için gerekli olan ad, soyad, e-posta adresi
              ve telefon numarası gibi kişisel bilgilerinizi toplayabiliriz. Bu
              veriler, yalnızca hizmetlerimizi sağlamak ve taleplerinizi
              karşılamak amacıyla kullanılacaktır.
            </p>
            <h2>2. Çerezler ve Diğer İzleme Teknolojileri</h2>
            <p>
              Web sitemizde, kullanıcı deneyiminizi iyileştirmek ve reklam
              içeriklerini kişiselleştirmek amacıyla çerezler kullanmaktayız.
              Google AdSense gibi üçüncü taraf reklam sağlayıcıları, web
              sitemizde görüntülenen reklamların etkinliğini artırmak için
              çerezleri kullanabilir. Çerezlerin kullanımını kabul etmemek
              isterseniz, tarayıcı ayarlarınızdan çerezleri devre dışı
              bırakabilirsiniz.
            </p>
            <h2>3. Kişisel Verilerin Paylaşımı</h2>
            <p>
              Topladığımız kişisel bilgiler, yalnızca aşağıdaki durumlarda
              üçüncü şahıslarla paylaşılabilir:
            </p>
            <ol>
              <li>Yasal yükümlülükler çerçevesinde.</li>
              <li>Kullanıcı izni alınarak.</li>
              <li>
                Hizmet sağlayıcılarımızla (örneğin, ödeme hizmetleri, veri
                analizi).
              </li>
              <h2>4. Veri Güvenliği</h2>
              <p>
                Kişisel verilerinizin güvenliği bizim için önemlidir.
                Verilerinizi korumak amacıyla SSL şifreleme teknolojisi
                kullanıyoruz. Verilerinizin yetkisiz erişime karşı korunması
                için gereken tüm teknik ve idari önlemleri almaktayız.
              </p>
              <h2>5. Kullanıcı Hakları</h2>
              <p>
                Kullanıcılar, kişisel verilerine erişim talep edebilir, bunların
                düzeltilmesini veya silinmesini isteyebilirler. Gizlilik
                politikamız hakkında sorularınız olursa, lütfen bize [e-posta
                adresi] üzerinden ulaşın.
              </p>
              <h2>6. Gizlilik Politikasındaki Değişiklikler</h2>
              <p>
                Bu gizlilik politikası zaman zaman güncellenebilir. Herhangi bir
                değişiklik yapıldığında, bu sayfa üzerinden duyurulacaktır.
              </p>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
